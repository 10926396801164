
import Vue from "vue";
import moment from "moment";
import { InformationData, Session } from "@/types/interfaces";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Session",
  props: {
    session: {
      type: Object as () => Session
    },
    profile: Object
  },
  data() {
    return {
      currentTime: {},
      sessionDate: "",
      sessionItems: [
        {
          name: "start_date",
          label: (this as any).$t("sessions.sessionItemsDate"),
          icon: "calendar",
          value: null
        },
        {
          name: "start_time",
          label: (this as any).$t("sessions.sessionItemsTime"),
          icon: "time-square",
          value: null
        },
        {
          name: "price",
          label: (this as any).$t("sessions.sessionItemsPaid"),
          icon: "price",
          value: null
        },
        // {
        //   name: "cancellation_fee",
        //   label: "Cancellation fee",
        //   icon: "seat-block",
        //   value: null
        // }
        {
          name: "duration",
          label: (this as any).$t("sessions.sessionItemsDuration"),
          icon: "time-circle",
          value: null
        }
      ] as InformationData[]
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    })
  },
  mounted(): void {
    // Change session date format
    //@ts-ignore
    const dateString = `${this.session.start_date} ${this.session.start_time}`;
    this.sessionDate = moment(dateString)
      .format("dddd Do of MMM, yyyy [at] hh:mm a")
      .toString();

    for (const key of Object.keys(this.session)) {
      const index = this.sessionItems.findIndex(i => i.name === key);
      if (index > -1) {
        if (key === "start_date") {
          this.sessionItems[index].value = moment(dateString)
            .format("Do MMMM, yyyy")
            .toString();
          continue;
        }
        if (key === "start_time") {
          this.sessionItems[index].value = moment(dateString)
            .format("hh:mm A")
            .toString();
          continue;
        } else if (key === "duration") {
          this.sessionItems[index].value = `${
            this.session[key as keyof Session]
          }'`;
        } else if (key === "price") {
          this.sessionItems[index].value = `${this.platformCurrency} ${
            this.session[key as keyof Session]
          }`;
        } else
          this.sessionItems[index].value = this.session[key as keyof Session];
      }
    }
  },
  methods: {
    generateIcs() {
      this.$store
        .dispatch("session/GENERATE_ICS", this.session.uuid)
        .then(data => {
          this.downloadURI(data.ics, "Mentoring Session");
        });
    },
    downloadURI(uri: any, name: any) {
      const link = document.createElement("a");
      link.setAttribute("download", name);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    onFeedback() {
      this.$router.push(`/sessions/${this.session.uuid}/feedback`);
    },
    getOtherUserProfile() {
      // return this.profile.type === "mentor" ? (this as any).$t('sessions.labelMentoree') : (this as any).$t('sessions.labelMentor');
      return this.profile.type === "mentor" ? "mentee" : "mentor";
    }
    // Calculate how much time to start session
    // countdown(deadline: string, date: InformationData) {
    //   const t = Date.parse(deadline) - Date.parse(new Date().toString());
    //   const seconds = Math.floor((t / 1000) % 60);
    //   const minutes = Math.floor((t / 1000 / 60) % 60);
    //   const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    //   const days = Math.floor(t / (1000 * 60 * 60 * 24));
    //   if (t > 0) {
    //     const currentTime = {
    //       total: t,
    //       days: days,
    //       hours: hours,
    //       minutes: minutes,
    //       seconds: seconds
    //     };
    //     date.value = this.currentTime
    //       ? `${currentTime.days ? currentTime.days + " days" : ""},
    //       ${currentTime.hours ? currentTime.hours + " hours" : ""},
    //       ${currentTime.minutes ? currentTime.minutes + " minutes" : ""},
    //       ${currentTime.seconds + " seconds"}`
    //       : "";
    //
    //     setTimeout(() => this.countdown(deadline, date), 1000);
    //   } else {
    //     this.currentTime = {};
    //   }
    // }
  }
});
