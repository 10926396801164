
import Vue from "vue";
import SessionMenu from "@/components/Session/SessionMenu.vue";
import Session from "@/components/Session/Session.vue";
import RecommendedMentors from "@/components/Mentor/RecommendedMentors.vue";
import { mapGetters } from "vuex";
import SessionsLoading from "@/components/Session/SessionsLoading.vue";

export default Vue.extend({
  name: "SessionsPage",
  components: {
    Session,
    SessionMenu,
    RecommendedMentors,
    SessionsLoading
  },
  data() {
    return {
      loading: true,
      data: [] as any,
      page: 1,
      load_more: false,
      tabs: [
        (this as any).$t("sessions.allTab"),
        (this as any).$t("sessions.completedTab"),
        (this as any).$t("sessions.upcomingTab"),
        (this as any).$t("sessions.othersTab")
      ],
      isIHave: true,
      currentTab: (this as any).$t("sessions.allTab"),
      filter: {}
    };
  },
  computed: {
    ...mapGetters({
      sessions: "session/getSessions",
      profile: "profile/getProfile"
    })
  },
  created() {
    this.getSessions(this.page);
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  methods: {
    onLoadMore() {
      this.page++;
      this.getSessions(this.page);
    },
    onTab(tab: any) {
      this.page = 1;
      this.data = [];
      this.currentTab = tab;
      this.getSessions(this.page);
    },
    onSearch(filter: any) {
      this.isIHave = false;
      this.data = [];
      this.filter = filter;
      this.getSessions(this.page);
    },
    getSessions(page: any) {
      this.loading = true;
      this.$store
        .dispatch("session/GET_ALL_SESSIONS", {
          page: page,
          type: this.currentTab,
          filter: this.filter
        })
        .then(data => {
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
          this.loading = false;
        });
    }
  }
});
